var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "init"
  }, [_c("div", {
    staticClass: "banner"
  }, [_vm._m(0), _c("div", {
    staticClass: "login-out"
  }, [_c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.LoginOut
    }
  }, [_c("i", {
    staticClass: "el-icon-table-lamp"
  }), _vm._v(_vm._s(_vm.$t("layout.logout")))])], 1)]), _c("div", {
    staticClass: "form-card in-three a-fadeinB"
  }, [_c("div", {
    staticClass: "pwd-tip"
  }, [_c("el-tooltip", {
    attrs: {
      content: _vm.$t("person-setting.reset-pwd-desc"),
      placement: "left",
      effect: "light"
    }
  }, [_c("i", {
    staticClass: "el-icon-question"
  })])], 1), _c("p", {
    staticClass: "in-one a-fadeinT"
  }, [_vm._v(_vm._s(_vm.$t("person-setting.reset-pwd")))]), _c("el-form", {
    ref: "modifyPwdForm",
    attrs: {
      model: _vm.pwdModify,
      rules: _vm.rules,
      "label-width": "auto"
    }
  }, [_c("el-form-item", {
    attrs: {
      minlength: 6,
      label: _vm.$t("person-setting.old-pwd"),
      prop: "password"
    }
  }, [_c("el-input", {
    attrs: {
      "show-password": ""
    },
    model: {
      value: _vm.pwdModify.password,
      callback: function callback($$v) {
        _vm.$set(_vm.pwdModify, "password", $$v);
      },
      expression: "pwdModify.password"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      minlength: 6,
      label: _vm.$t("person-setting.new-pwd"),
      prop: "newPassword"
    }
  }, [_c("el-input", {
    attrs: {
      "show-password": ""
    },
    model: {
      value: _vm.pwdModify.newPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.pwdModify, "newPassword", $$v);
      },
      expression: "pwdModify.newPassword"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      minlength: 6,
      label: _vm.$t("person-setting.confirm-pwd"),
      prop: "confirmPassword"
    }
  }, [_c("el-input", {
    attrs: {
      "show-password": ""
    },
    model: {
      value: _vm.pwdModify.confirmPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.pwdModify, "confirmPassword", $$v);
      },
      expression: "pwdModify.confirmPassword"
    }
  })], 1), _c("el-form-item", [_c("div", {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.confirm")))])], 1)])], 1)], 1), _c("BottomInfo")], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "logo"
  }, [_c("div", [_c("img", {
    staticClass: "logo_login",
    attrs: {
      src: require("@/assets/logo_login.png"),
      alt: ""
    }
  })]), _c("div", [_c("span", {
    staticClass: "title"
  }, [_vm._v("Zenlayer DNS")])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };